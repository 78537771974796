import React from "react"
import { Layout } from "../../components/layout"
import { SEO } from "../../components/seo"

const Page = () => {
  return (
    <>
      <SEO title="Jobba på Brandbilen" />
      <Layout>
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
          <div className="relative max-w-xl mx-auto">
            <svg className="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
              <defs>
                <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
            </svg>
            <svg className="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
              <defs>
                <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
            </svg>
            <div className="text-center">
              <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-warm-grey-900 sm:text-4xl sm:leading-10">
                Förskollärare
              </h2>
              <p className="mt-4 text-lg leading-6 text-warm-grey-500">
                Vill du vara med i ett litet arbetslag där din kunskap och dina idéer snabbt kan testas?
              </p>
              <p className="mt-4 text-lg leading-6 text-warm-grey-500">
                Detta är en öppen anmälan så tveka inte att höra av dig om du är intresserad eller vill veta mer!
              </p>
            </div>
            <div className="mt-12">
              <form
                data-netlify="true"
                netlify-honeypot="open_work_application_bot_field"
                name="contact-open-work-application"
                method="POST"
                action="/jobba-pa-brandbilen/tack"
                className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <input type="hidden" name="form-name" value="contact-open-work-application" />
                <div className="hidden">
                  <label htmlFor="open_work_application_bot_field">Don’t fill this out if you're human</label>
                  <input id="open_work_application_bot_field" name="open_work_application_bot_field" />
                </div>
                <div>
                  <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-warm-grey-700">Förnamn</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input name="first_name" id="first_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
                  </div>
                </div>
                <div>
                  <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-warm-grey-700">Efternamn</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input name="first_name" id="first_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="email" className="block text-sm font-medium leading-5 text-warm-grey-700">E-post</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input name="email" id="email" type="email" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="phone" className="block text-sm font-medium leading-5 text-warm-grey-700">Telefon</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input name="phone" id="phone" type="text" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block text-sm font-medium leading-5 text-warm-grey-700">Meddelande</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <textarea name="message" id="message" rows={4} className="form-textarea py-3 px-4 block w-full transition ease-in-out duration-150"></textarea>
                  </div>
                  <p className="mt-2 text-sm text-warm-grey-500" id="message-description">Skriv en kort beskrivning av dig själv och dina erfarenheter.</p>
                </div>
                <div className="sm:col-span-2">
                  <span className="w-full inline-flex rounded-md shadow-sm">
                    <button type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:border-bb-red-700 focus:shadow-outline-bb-red active:bg-bb-red-700 transition ease-in-out duration-150">
                      Skicka
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}



export default Page
